<template>
    <v-chip
      pill
      link
      color="green"
      :to="detailsUrl"
    >
      {{ displayName }}
    </v-chip>
</template>

<script>
export default {
  props: {
    vehicle: { type: Object, required: true }
  },
  computed: {
    displayName () {
      return this.vehicle.name ? this.vehicle.name : this.vehicle.uniqueIdentifier
    },
    detailsUrl () {
      return `/vehicles/${this.vehicle.id}`
    }
  }
}
</script>
