<template>
    <v-chip
      pill
      link
      color="orange"
      :to="detailsUrl"
    >
      {{ driver.name }}
    </v-chip>
</template>

<script>
export default {
  props: {
    driver: { type: Object, required: true }
  },
  computed: {
    detailsUrl () {
      return `/drivers/${this.driver.id}`
    }
  }
}
</script>
