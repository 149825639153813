<template>
  <!-- Title -->
  <div>
    <v-row justify="space-around">
      <v-skeleton-loader
        v-if="isLoading"
        type="chip"
      >
      </v-skeleton-loader>
      <h1 v-else class="text-center">
        {{ system.name }}
      </h1>
    </v-row>

    <!-- Content -->
    <v-row>
      <!-- General information -->
      <v-col cols="12" md="4">
        <v-skeleton-loader
          v-if="isLoading"
          class="mx-auto elevation-1"
          type="card-heading, list-item-two-line"
        />
        <v-card
          v-else
          class="mx-auto"
        >
          <v-system-bar class="py-5">
            <h3>{{ $t('systems.details.general-title') }}</h3>
          </v-system-bar>

          <v-container>
            <v-row>
              <v-col sm="6">
                <span class="font-weight-medium mb-0">{{ $t('systems.details.last-used') }}</span>
              </v-col>
              <v-col sm="6">
                <span v-if="latestSession == null">-</span>
                <DateTimeDisplay
                  v-else
                  :date="latestSession.startTime"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="6">
                <span class="font-weight-medium mb-0">{{ $t('systems.details.software') }}</span>
              </v-col>
              <v-col sm="6">
                <SoftwareVersionDisplay :softwareVersion="softwareVersion"/>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>

      <!-- Vehicles -->
      <v-col cols="12" md="4">
        <v-subheader>{{ $t('systems.details.vehicles') }}</v-subheader>
        <div v-if="isLoading" class="d-flex flex-wrap">
          <v-skeleton-loader
            v-for="index in 5" :key="index"
            type="chip"
            class="me-1 mb-1"
          />
        </div>
        <v-chip-group v-else column>
          <VehicleChip
            v-for="vehicle in vehicles"
            :key="vehicle.id"
            :vehicle="vehicle"
          />
        </v-chip-group>
      </v-col>

      <!-- Drivers -->
      <v-col cols="12" md="4">
        <v-subheader>{{ $t('systems.details.drivers') }}</v-subheader>
        <div v-if="isLoading" class="d-flex flex-wrap">
          <v-skeleton-loader
            v-for="index in 3" :key="index"
            type="chip"
            class="me-1 mb-1"
          />
        </div>
        <v-chip-group v-else column>
          <DriverChip
            v-for="driver in drivers"
            :key="driver.id"
            :driver="driver"
          />
        </v-chip-group>
      </v-col>
    </v-row>

    <!-- ConfigurationBackups -->
    <v-row v-if="isAdmin">
      <v-col cols="12">
        <ConfigurationBackupsTable
          :items="configurationBackups"
          :totalSize="backupsTotalSize"
          :page-size="backupsPageSize"
          :page-number="backupsPageNumber"
          :loading="isLoadingBackups"
          :headers="backupTableHeaders"
          :features="backupTableFeatures"
          :title="$t('configuration-backups.table.title')"
          @pageChanged="backupsPageChanged"
          @sortingChanged="onBackupsSortingChanged"
          @refreshRequested="fetchAllData"
        />
      </v-col>
    </v-row>

    <!-- Sessions -->
    <v-row>
      <v-col cols="12">
        <SessionsTable
          :items="sessions"
          :totalSize="amountOfSessions"
          :use-paging="false"
          :loading="isLoading"
          :headers="sessionTableHeaders"
          :title="$t('systems.details.latest-sessions-table-title')"
          :features="sessionTableFeatures"
          @refreshRequested="fetchAllData"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from 'axios'
import debounce from 'lodash/debounce'
import { mapActions, mapGetters } from 'vuex'
import ConfigurationBackupsTable from '@/components/tables/ConfigurationBackupsTable'
import DateTimeDisplay from '@/components/DateTimeDisplay'
import DriverChip from '@/components/DriverChip'
import sessionsHelper from '@/components/helpers/sessionsHelper'
import SessionsTable from '@/components/tables/SessionsTable'
import SoftwareVersionDisplay from '@/components/SoftwareVersionDisplay'
import VehicleChip from '@/components/VehicleChip'

export default {
  components: { ConfigurationBackupsTable, DateTimeDisplay, DriverChip, SessionsTable, SoftwareVersionDisplay, VehicleChip },
  data () {
    return {
      system: null,
      sessions: [],
      configurationBackups: [],
      drivers: [],
      vehicles: [],
      softwareVersion: null,
      isLoading: true,
      amountOfSessions: 5,
      backupsPageSize: 5,
      backupsPageNumber: 1,
      backupsTotalSize: 0,
      // No features, because this table is only for viewing.
      backupTableFeatures: ['download'],
      sessionTableFeatures: [],
      backupSorting: undefined,
      isLoadingBackups: false
    }
  },
  computed: {
    ...mapGetters('account', ['isAdmin']),
    id () {
      return this.$route.params.systemId
    },
    latestSession () {
      return sessionsHelper.getLatestSession(this.sessions)
    },
    backupTableHeaders () {
      return [
        { text: this.$t('configuration-backups.table.columns.timestamp'), value: 'timestamp', sortable: true },
        { text: this.$t('configuration-backups.table.columns.json-content'), value: 'jsonContent', sortable: false },
        { text: '', value: 'actions', sortable: false, align: 'right' }
      ]
    },
    sessionTableHeaders () {
      return [
        { text: this.$t('sessions.table.columns.date'), value: 'startTime', sortable: false },
        { text: this.$t('sessions.table.columns.driver'), value: 'driver', sortable: false },
        { text: this.$t('sessions.table.columns.vehicle'), value: 'vehicle', sortable: false },
        { text: this.$t('sessions.table.columns.duration'), value: 'duration', sortable: false },
        { text: this.$t('sessions.table.columns.quantity-fuelled'), value: 'quantityFuelled', sortable: false }
      ]
    }
  },
  methods: {
    ...mapActions('snackbar', ['showSnackbar']),
    ...mapActions('drivers', ['fetchDriversBySystem']),
    ...mapActions('vehicles', ['fetchVehiclesBySystem']),
    ...mapActions('systems', ['fetchSystem']),
    ...mapActions('configurationBackups', ['fetchConfigurationBackupsBySystemId']),
    async backupsPageChanged (newPageNumber) {
      // Update the current page number.
      this.backupsPageNumber = newPageNumber
      // Fetch data with the new page number.
      await this.fetchBackupsDebounced(this.backupsPageNumber, this.backupsPageSize, this.backupSorting)
    },
    async onBackupsSortingChanged (newSorting) {
      this.backupSorting = newSorting
      await this.fetchBackupsDebounced(this.backupsPageNumber, this.backupsPageSize, this.backupSorting)
    },
    fetchBackupsDebounced: debounce(async function (pageNumber, pageSize, sorting) {
      await this.fetchBackupsPage(pageNumber, pageSize, sorting)
    }, 250),
    async fetchBackupsPage (pageNumber, pageSize, sorting) {
      this.isLoadingBackups = true
      try {
        const pagedResponse = await this.fetchConfigurationBackupsBySystemId({ systemId: this.id, pageNumber, pageSize, sorting })
        this.configurationBackups = pagedResponse.items
        this.backupsTotalSize = pagedResponse.totalSize
      } catch (error) {
        this.showSnackbar({
          role: 'error',
          messages: [this.$t('errors.loading-data-failed')],
          duration: 5000
        })
      } finally {
        this.isLoadingBackups = false
      }
    },
    async fetchSessions (systemId) {
      return await axios.get(`systems/${systemId}/sessions`, {
        params: {
          pageNumber: 1,
          pageSize: this.amountOfSessions,
          sorting: 'startTime-desc'
        }
      })
    },
    async fetchSoftwareVersion (systemId) {
      return await axios.get(`systems/${systemId}/softwareVersion`)
    },
    async fetchAllData () {
      this.isLoading = true
      try {
        // Retrieve all required data.
        // Note: Not fetching all software versions, if the sessions table would display those, they would also need to be fetched.
        const [systemResponse, sessionsResponse, driversResponse, vehiclesResponse, versionResponse] = await Promise.all([
          this.fetchSystem(this.id),
          this.fetchSessions(this.id),
          this.fetchDriversBySystem(this.id),
          this.fetchVehiclesBySystem(this.id),
          this.fetchSoftwareVersion(this.id),
          this.isAdmin ? this.fetchBackupsDebounced(this.backupsPageNumber, this.backupsPageSize, this.backupSorting) : Promise.resolve(),
          // TODO: Remove this.
          new Promise(resolve => setTimeout(resolve, 2000))
        ])

        this.system = systemResponse.data
        this.sessions = sessionsResponse.data.items
        this.drivers = driversResponse.data.items
        this.vehicles = vehiclesResponse.data.items
        this.softwareVersion = versionResponse.data === '' ? null : versionResponse.data
      } catch (error) {
        this.showSnackbar({
          role: 'error',
          messages: [this.$t('errors.loading-data-failed')],
          duration: 5000
        })
      } finally {
        this.isLoading = false
      }
    }
  },
  async mounted () {
    await this.fetchAllData()
  }
}
</script>
