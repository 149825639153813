<template>
  <span> {{ versionAsText }} </span>
</template>

<script>
import semanticVersionHelper from '@/components/helpers/semanticVersionHelper'

export default {
  props: {
    softwareVersion: { type: Object, default: null }
  },
  data () {
    return {
      versionAsText: `${this.$t('common.loading').toLowerCase()}...`
    }
  },
  watch: {
    softwareVersion: {
      immediate: true,
      handler (newValue, oldValue) {
        this.versionAsText = semanticVersionHelper.versionToString(newValue)
      }
    }
  }
}
</script>
