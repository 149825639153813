export default {
  /**
   * Gets the latest session from an array of sessions.
   * @param {Array} sessions The sessions to search in.
   * @returns {Object} The latest session.
   */
  getLatestSession: function (sessions) {
    if (sessions == null || sessions.length === 0) {
      return null
    }

    let latest = sessions[0]
    for (let i = 0; i < sessions.length; i++) {
      if (sessions[i].startTime > latest.startTime) {
        latest = sessions[i]
      }
    }

    return latest
  }
}
