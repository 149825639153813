<template>
  <div>
    <v-data-table
      :items="items"
      :server-items-length="totalSize"
      :options.sync="options"
      :multi-sort="true"
      :headers="computedHeaders"
      :loading="loading"
      disable-pagination
      hide-default-footer
      class="elevation-2"
      :id="id"
    >
      <!-- Title bar -->
      <template v-if="title != null" v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>{{ title }}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
      </template>

      <!-- Empty display -->
      <template v-slot:no-data>
        <h3 class="mt-3"> {{$t('errors.no-data-available')}} </h3>
        <v-btn
          icon
          x-large
          class="my-2"
          color="primary"
          @click="$emit('refreshRequested')"
        >
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </template>

      <!-- Custom cells -->
      <template v-slot:[`item.timestamp`]="{value}">
        <DateTimeDisplay :date="value"/>
      </template>
      <template v-slot:[`item.jsonContent`]="{value}">
        <span>{{ toDisplayJson(value) }}</span>
      </template>
      <template v-slot:[`item.system`]="{item}">
        <SystemLinkDisplay :systemId="item.systemId"/>
      </template>

      <!-- Custom actions -->
      <template v-slot:[`item.actions`]="{item}">
        <v-btn
          v-if="canDownload"
          icon
          @click="downloadBackup(item)"
          color="info">
          <v-icon>
            mdi-download
          </v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <v-pagination
      v-if="usePaging && totalSize > 0"
      v-model="currentPage"
      :length="totalPages"
      total-visible="7"
      next-icon="mdi-menu-right"
      prev-icon="mdi-menu-left"
      @input="handlePageChange"
      class="mt-2"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DateTimeDisplay from '@/components/DateTimeDisplay'
import Sorting from '@/models/sorting'
import SystemLinkDisplay from '@/components/SystemLinkDisplay'

export default {
  components: { DateTimeDisplay, SystemLinkDisplay },
  emits: ['pageChanged', 'sortingChanged', 'refreshRequested'],
  props: {
    /**
     * The items to display.
     */
    items: { type: Array, required: true },
    title: { type: String, default: null },
    totalSize: { type: Number, default: 0 },
    /**
     * The headers and columns to display.
     */
    headers: { type: Array },
    /**
     * The features to enable for this table.
     * Supply with an array of the desired features ('download').
     */
    features: { type: Array, default: () => ['download'] },
    /**
     * True will enable pagination buttons/inputs, false will disable them. Defaults to true.
     */
    usePaging: { type: Boolean, default: true },
    pageSize: { type: Number, default: null },
    pageNumber: { type: Number, default: null },
    loading: { type: Boolean, default: false },
    id: { type: String, default: 'configuration-backups-table' }
  },
  data () {
    return {
      currentPage: 0,
      options: {}
    }
  },
  computed: {
    ...mapGetters('systems', ['getSystemById']),
    totalPages () {
      return this.totalSize === 0 || this.pageSize === 0 ? 0 : Math.ceil(this.totalSize / this.pageSize)
    },
    canDownload () {
      return this.features && this.features.includes('download')
    },
    computedHeaders () {
      // Both the injected (prop) and default headers must be wrapped in a computed
      // to ensure translations don't require a page refresh.
      return this.headers && this.headers.length > 0 ? this.headers : this.defaultHeaders
    },
    defaultHeaders () {
      return [
        { text: this.$t('configuration-backups.table.columns.timestamp'), value: 'timestamp', sortable: true },
        { text: this.$t('configuration-backups.table.columns.system'), value: 'system', sortable: true },
        { text: this.$t('configuration-backups.table.columns.json-content'), value: 'jsonContent', sortable: false },
        { text: '', value: 'actions', sortable: false, align: 'right' }
      ]
    }
  },
  methods: {
    handlePageChange (value) {
      this.$emit('pageChanged', value)
    },
    toDisplayJson (json) {
      return json.length < 50 ? json : `${json.substring(0, 50).trimEnd()}...`
    },
    downloadBackup (backup) {
      const file = new Blob([backup.jsonContent], { type: 'application/json' })
      const fileFriendlyTimestamp = backup.timestamp.replace(':', '_')
      const systemName = this.getSystemById(backup.systemId).name
      const url = URL.createObjectURL(file)

      // Creates an invisible link an programmatically clicks it to start the download.
      const a = document.createElement('a')
      a.href = url
      a.download = `${systemName}_ConfigurationBackup_${fileFriendlyTimestamp}`
      document.body.appendChild(a)
      a.click()
      setTimeout(function () {
        document.body.removeChild(a)
        window.URL.revokeObjectURL(url)
      }, 0)
    }
  },
  mounted () {
    this.currentPage = this.pageNumber
  },
  watch: {
    totalSize (newValue, oldValue) {
      if (this.currentPage > this.totalPages) {
        console.warn(`Page ${this.currentPage} does not exist, routing back to page 1`)
        this.currentPage = 1
        this.$emit('pageChanged', this.currentPage)
      }
    },
    options: {
      immediate: false,
      handler (newValue) {
        this.$emit('sortingChanged', new Sorting(this.options.sortBy, this.options.sortDesc))
      }
    }
  }
}
</script>
